export let page_edit_user = `
<span class="in_t">Users</span>
        <hr style="color:var(--clr18)" width="100%">
        <div
            style="border:orangered 3px solid;border-radius: 30px;display: flex;flex-direction: column;align-items: center;width:80%;">
            <span style="font-size:3vh;color:var(--yellow);width:80%;">Add Users</span><iframe
                href="index.html#/register"></iframe>
        </div>
        <hr style="color:var(--clr18)" width="50%">
        <input type="answer" id="us_user" class="_in_aq" placeholder="username">

`
export default {page_edit_user}